
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import SingleBlock from "@/components/blocks/Single.vue";
  import TripleBlock from "@/components/blocks/Triple.vue";
  import NoticeAlert from "@/components/alerts/Notice.vue";
  import PageButton from "@/components/buttons/Page.vue";

  export default {
    name: "ProductenView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      TripleBlock,
      NoticeAlert,
      PageButton,
    },
  };
