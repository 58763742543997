import { Router, RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";
import Werkzaamheden from "@/views/werkzaamheden/Werkzaamheden.vue";
import Ontwikkeling from "@/views/werkzaamheden/ontwikkeling/Ontwikkeling.vue";
import Onderhoud from "@/views/werkzaamheden/onderhoud/Onderhoud.vue";
import Producten from "@/views/producten/Producten.vue";
import Websites from "@/views/producten/websites/Websites.vue";
import Toekomstig from "@/views/producten/toekomstig/Toekomstig.vue";
import Tarieven from "@/views/tarieven/Tarieven.vue";
import Contact from "@/views/contact/Contact.vue";
import AlgemeneVoorwaarden from "@/views/algemeneVoorwaarden/AlgemeneVoorwaarden.vue";
import Sitemap from "@/views/sitemap/Sitemap.vue";
import PageNotFound from "@/views/errors/PageNotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "MaatOntwikkeling",
        component: Index,
        meta: {
            title: "MaatOntwikkeling",
        },
    },
    {
        path: "/werkzaamheden",
        name: "Werkzaamheden",
        component: Werkzaamheden,
        meta: {
            title: "Werkzaamheden",
        },
    },
    {
        path: "/werkzaamheden/ontwikkeling",
        name: "Ontwikkeling",
        component: Ontwikkeling,
        meta: {
            title: "Ontwikkeling",
        },
    },
    {
        path: "/werkzaamheden/onderhoud",
        name: "Onderhoud",
        component: Onderhoud,
        meta: {
            title: "Onderhoud",
        },
    },
    {
        path: "/producten",
        name: "Producten",
        component: Producten,
        meta: {
            title: "Producten",
        },
    },
    {
        path: "/producten/websites",
        name: "Websites",
        component: Websites,
        meta: {
            title: "Websites",
        },
    },
    {
        path: "/producten/toekomstig",
        name: "Toekomstig",
        component: Toekomstig,
        meta: {
            title: "Toekomstig",
        },
    },
    {
        path: "/tarieven",
        name: "Tarieven",
        component: Tarieven,
        meta: {
            title: "Tarieven",
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Contact",
        },
    },
    {
        path: "/algemene_voorwaarden",
        name: "AlgemeneVoorwaarden",
        component: AlgemeneVoorwaarden,
        meta: {
            title: "Algemene voorwaarden",
            hideInNavigator: true,
        },
    },
    {
        path: "/sitemap",
        name: "Sitemap",
        component: Sitemap,
        meta: {
            title: "Sitemap",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {
            title: "Pagina niet gevonden",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
];

const router: Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to) => {
    if (to.meta.title == null) {
        document.title = "MaatOntwikkeling | Software op maat";
    } else {
        let appendix: string;

        if (to.fullPath == "/") {
            appendix = " | Software op maat";
        } else {
            appendix = " | MaatOntwikkeling";
        }

        document.title = to.meta.title as string + appendix;
    }
});

export default router;
