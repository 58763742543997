
  import ImageLoader from "@/assets/scripts/ImageLoader";
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import SingleBlock from "@/components/blocks/Single.vue";
  import TripleBlock from "@/components/blocks/Triple.vue";
  import NoticeAlert from "@/components/alerts/Notice.vue";

  export default {
    name: "OnderhoudView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      TripleBlock,
      NoticeAlert,
    },
    data() {
      return {
        onderhoudImage: ImageLoader.loadImageSrc("assets/images/decorators/onderhoud.jpg"),
      };
    },
  };
