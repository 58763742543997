
  import { RouteRecord } from "vue-router";
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import SingleBlock from "@/components/blocks/Single.vue";
  import Sitemap from "@/components/sitemap/Sitemap.vue";
  import RouteExtractor from "@/assets/scripts/RouteExtractor";

  export default {
    name: "SitemapView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      Sitemap,
    },
  };
