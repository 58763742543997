
    export default {
        name: "PriceTag",
        props: {
            price: {
                type: String,
                required: true,
            },
            unit: {
                type: String,
                required: true,
            },
        },
    };
