
  import ImageLoader from "@/assets/scripts/ImageLoader";
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import TripleBlock from "@/components/blocks/Triple.vue";

  export default {
    name: "OntwikkelingView",
    components: {
      HeaderLayout,
      FooterLayout,
      TripleBlock,
    },
    data() {
      return {
        ontwikkelingImage: ImageLoader.loadImageSrc("assets/images/decorators/ontwikkeling.jpg"),
      };
    },
  };
