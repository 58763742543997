
  import ImageLoader from "@/assets/scripts/ImageLoader";
  import HeaderLayout from "@/components/layouts/Header.vue";
  import FooterLayout from "@/components/layouts/Footer.vue";
  import SingleBlock from "@/components/blocks/Single.vue";
  import TripleBlock from "@/components/blocks/Triple.vue";
  import NoticeAlert from "@/components/alerts/Notice.vue";
  import PriceTag from "@/components/priceTag/PriceTag.vue";

  export default {
    name: "TarievenView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      TripleBlock,
      NoticeAlert,
      PriceTag,
    },
    data() {
      return {
        tarievenImage: ImageLoader.loadImageSrc("assets/images/decorators/tarieven.jpg"),
        googleMapsUrl: "https://www.google.com/maps/dir//M.H.+Trompstraat+95,+Brielle/@51.9021324,4.1580316,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c4527ee321c85f:0xa84c6ea783e63287!2m2!1d4.1602203!2d51.9021291",
      };
    },
  };
